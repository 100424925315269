import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Container } from './styles';

import Logo from '../../assets/logo.svg';

import { Header } from '../../components/Header';

import { api } from '../../services/api';

export const Login: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function onSubmit(data: any) {
    setLoading(true);

    try {
      const response = await api.post('session', {
        user: data.login,
        password: data.password,
      });

      const { user } = response.data;

      if (user && user === 'riotec') {
        localStorage.removeItem('@rio-tec-inea');

        localStorage.setItem(
          '@rio-tec',
          JSON.stringify({
            signed: true,
          })
        );

        history.push('/restrito');

        setLoading(false);

        return;
      }

      if (user && user === 'inea') {
        localStorage.removeItem('@rio-tec');

        localStorage.setItem(
          '@rio-tec-inea',
          JSON.stringify({
            signedInea: true,
          })
        );

        history.push('/inea');

        setLoading(false);

        return;
      }
    } catch (err) {
      toast.error('Falha no login, verifique seu nome e senha.', {
        className: 'toast',
      });

      setLoading(false);
    }
  }

  return (
    <>
      <Header pageName="login" />
      
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <img src={Logo} alt="logo" />

          <label>Login</label>
          <input 
            maxLength={20} 
            placeholder="Digite seu login..."
            disabled={isSubmitting}
            {...register('login', { required: 'LOGIN OBRIGATÓRIO' })}
          />
          {errors.login && <span>{errors.login.message}</span>}

          <label>Senha</label>
          <input 
            placeholder="Digite sua senha..."
            maxLength={20}
            disabled={isSubmitting}
            type="password"
            {...register('password', { required: 'SENHA OBRIGATÓRIA' })}
          />
          {errors.password && <span>{errors.password.message}</span>}

          {loading ? (
            <button type="button" disabled>AGUARDE...</button>
          ) : (
            <button type="submit">ENTRAR</button>
          )}
        </form>
      </Container>
    </>
  );
}
