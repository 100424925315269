import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SwipeCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

import { Container, HomeContainer, ServicesContainer, PartnersContainer } from './styles';

import defMang from '../../assets/defMang.jpg';
import desenvolvimento from '../../assets/desenvolvimento.jpg';
import edf from '../../assets/edf.png';
import hidrometria from '../../assets/hidrometria.jpg';
import inea from '../../assets/inea.png';
import monitoramento from '../../assets/monitoramento.jpg';
import nEnergia from '../../assets/nEnergia.png';
import prefMang from '../../assets/prefMang.png';
import qualidade from '../../assets/qualidade.jpg';
import rio2016 from '../../assets/rio2016.png';
import sAntonio from '../../assets/sAntonio.png';
import sedimentometria from '../../assets/sedimentometria.jpg';
import sefac from '../../assets/sefac.png';
import topobatimetria from '../../assets/topobatimetria.jpg';


SwipeCore.use([Navigation, Pagination, Autoplay]);

export function Home() {
  const [slideCount, setSlideCount] = useState(1);
  const [currentLink, setCurrentLink] = useState('/servicos/hidrometria');

  const services = [
    {
      link: '/servicos/hidrometria',
      name: 'Hidrometria', 
    },
    {
      link: '/servicos/monitoramento',
      name: 'Monitoramento telemétrico',
    },
    {
      link: '/servicos/instalacao',
      name: 'Instalação e operação', 
    },
    {
      link: '/servicos/locacao',
      name: 'Locação', 
    },
    {
      link: '/servicos/desenvolvimento',
      name: 'Fornecimento de software resolução conjunta ANA/ANEEL nº 03 de agosto 2010',
    },
    {
      link: '/servicos/monitoramento',
      name: 'Estações Meteorologicas',
    },
    {
      link: '/servicos/monitoramento',
      name: 'Estações Hidrológicas',
    },
    {
      link: '/servicos/monitoramento',
      name: 'Estações de Qualidade da água'
    },
    
  ];

  const history = useHistory();

  function onSlideChange() {

    switch(slideCount) {
      case 1 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[0].link);
        break;
      case 2 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[1].link);
        break;
      case 3 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[2].link);
        break;
      case 4 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[3].link);
        break;
      case 5 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[4].link);
        break;
      case 6 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[5].link);
        break;
      case 7 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[6].link);
        break;
      case 8 :
        setSlideCount(slideCount + 1);
        setCurrentLink(services[7].link);
        break;
      case 9 :
        setSlideCount(1);
        setCurrentLink(services[0].link);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header pageName='home' />

      <Container>
        <HomeContainer>
          <div>
            <h1>Soluções para sua empresa</h1>
            {/**
             * <p>
              Serviços de Hidrometria, Monitoramento telemétrico, instalação e operação, Manutenção, Locação, e Fornecimento de sofware.
            </p>

             */}

            <Swiper
              className="slideContainerBanner"
              slidesPerView={1}
              loop
              autoplay={{
                delay: 2500,
                disableOnInteraction: false
              }}
              onSlideChange={onSlideChange}
            >
              {services.map(service => (
                <SwiperSlide key={service.name} className='slideBanner'>
                  <p>
                    {service.name}
                  </p>
                </SwiperSlide>
              ))}
              
            </Swiper>

            <button onClick={() => history.push(currentLink)} type="button">SAIBA MAIS</button>
          </div>
        </HomeContainer>

        <ServicesContainer>
          <h1>Nossos Serviços</h1>

          <section>
            <Swiper
              className="slideContainer"
              spaceBetween={20}
              slidesPerView={3}
              loop
              autoplay={{
                delay: 2500,
                disableOnInteraction: true
              }}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide className="slide">
                <img src={sedimentometria} alt="Slide 1" />
                <h2>Sedimentometria</h2>
                <p>
                  Realização de serviços de coleta de amostra de sedimentos em suspensão e de fundo em seções transversais de medição de vazão liquida e determinação da das descargas sólidas em suspensão, de fundo e total, caracterizando o transporte de sedimentos em cursos d’água
                </p>

                <button onClick={() => history.push('/servicos/sedimentometria')} type="button">SAIBA MAIS</button>
              </SwiperSlide>
              <SwiperSlide className="slide">
                <img src={hidrometria} alt="Slide 2" />
                <h2>Hidrometria</h2>
                <p>
                  Realização de serviços de implantação de sistemas de monitoramento hidrometeorológico e de estudos hidrológicos básicos, para o conhecimento das características hidrológicas de uma determinada região visando o aproveitamento racional de seus recursos hídricos naturais. 
                </p>

                <button onClick={() => history.push('/servicos/hidrometria')} type="button">SAIBA MAIS</button>
              </SwiperSlide>
              <SwiperSlide className="slide">
                <img src={qualidade} alt="Slide 3" />
                <h2>Qualidade da água</h2>
                <p>
                  Realização de programas de monitoramento da qualidade da água em rios, canais, lagos e reservatórios visando avaliar o equilíbrio ecológico destes ambientes aquáticos e seu estado de conservação. O monitoramento visa identificar as substâncias presentes nesses corpos hídricos, medidas por meio de parâmetros
                </p>

                <button onClick={() => history.push('/servicos/qualidade')} type="button">SAIBA MAIS</button>
              </SwiperSlide>
              <SwiperSlide className="slide">
                <img src={topobatimetria} alt="Slide 4" />
                <h2>Topobatimetria</h2>
                <p>
                  Realização de levantamentos topobatimétricos de seções transversais em rios, canais, lagos e reservatórios para controle dos processos de erosão e assoreamento, abrangendo:
                  <br />• Levantamento topográfico da parte seca da seção transversal a partir do seu nivelamento geométrico;
                  <br />• Batimetria da parte molhada da seção utilizando guincho hidrométrico ou ecobatimetro e para o posicionamento do barco: teodolitos, distanciômetro ou gps;
                </p>
                <button onClick={() => history.push('/servicos/topobatimetria')} type="button">SAIBA MAIS</button>
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={monitoramento} alt="Slide 5" />
                <h2>Monitoramento Telemétrico</h2>
                <p>
                  Adequação, implantação de Estações telemétricas para monitoramento automático de parâmetros ambientais, processamento dos dados para atendimento a resolução conjunta nº 03 ANA/ANEEL de agosto 2010; utilizando tecnologias via rádio, GPRS, Satélite, fibra ótica de acordo com a necessidade do cliente.
                </p>
                <button onClick={() => history.push('/servicos/monitoramento')} type="button">SAIBA MAIS</button>
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={desenvolvimento} alt="Slide 6" />
                <h2>Desenvolvimento de software</h2>
                <p>
                  Soluções personalizadas em software de monitoramento remoto de dados.   Permite acesso aos dados de estações telemétricas remotamente em tempo real por dispositivos móveis de forma segura e acionável, geração de gráficos, download dos dados em formato *.txt, *.CSV, processamento e transmissão de dados para atender a resolução conjunta ANA/ANEEL nº 03 de agosto 2010.
                </p>
                <button onClick={() => history.push('/servicos/desenvolvimento')} type="button">SAIBA MAIS</button>
              </SwiperSlide>
            </Swiper>
          </section>
        </ServicesContainer>
      
        <PartnersContainer>
          <h1>Nossos parceiros</h1>

          <div>
            <Swiper
              className="slideContainer"
              spaceBetween={20}
              slidesPerView={4}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false
              }}
            >
              <SwiperSlide className="slide">
                <img src={inea} alt="INEA" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={nEnergia} alt="Natural Energia" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={sefac} alt="SEFAC" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={prefMang} alt="Prefeitura de Mangaratiba" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={sAntonio} alt="Santo Antônio Energia" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={rio2016} alt="Rio 2016" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={edf} alt="EDF Norte Fluminense" />
              </SwiperSlide>

              <SwiperSlide className="slide">
                <img src={defMang} alt="Defesa Civil - Mangaratiba" />
              </SwiperSlide>
            </Swiper>
          </div>
        </PartnersContainer>

        <Footer />

      </Container>
    </>
  )
}
