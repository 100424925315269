import styled from 'styled-components';

export const Container = styled.div`
  padding: 6rem 0 2rem;
  max-width: 900px;
  margin: 0 auto;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    box-shadow: 1px 8px 10px rgba(42, 59, 69, 0.1);
    background: var(--blue-100);
    border-radius: 6px;
    padding: 2rem 0;

    > section {
      background: var(--blue-800);
      color: var(--white);

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      font-size: 1.3rem;
      width: 100%;
    }

    > p {
      padding: 0 2rem;
      margin: 1rem 0;
      width: calc(100% - 2rem);
    }

    > div {
      display: flex;
      justify-content: center;
      padding: 0 2rem;
      margin: 0.5rem 0;

      > svg {
        font-size: 2rem;
        color: var(--gray-600);
      }

      > p {
        margin-left: 0.5rem;
        width: calc(100% - 4rem);
      }
    }
  }

  > section {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    
    > button {
      margin-top: 3rem;
      padding: 0.8rem 2.5rem;
      font-size: 1.3rem;
      font-weight: bold;
      color: var(--white);
      background: var(--blue-500);
      border: 0;
      border-radius: 4px;
      box-shadow: 0px 6px 6px rgba(29, 36, 44, 0.25);

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;
