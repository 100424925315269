import styled from 'styled-components';

export const ContactContainer = styled.div`
  background: var(--blue-700);
  
  > div {
    margin: 2rem auto 0;
    max-width: 900px;
    padding: 2rem 0;

    display: flex;
    justify-content: space-between;

    > section {
      color: var(--white);
      text-align: left;

      > h1 {
        font-size: 2.5rem;
      }

      > h2 {
        margin-top: 0.7rem;
        font-size: 1rem;
        font-weight: 400;
        width: 15rem;
        color: var(--gray-500);
        line-height: 1.4rem;
      }

      > div {
        margin-top: 2.5rem;

        > section {
          display: flex;
          align-items: center;

          & + section {
            margin-top: 1.4rem;
          }

          > svg {
            font-size: 1.4rem;
          }

          > p {
            margin-left: 0.8rem;
          }

          > a {
            margin-left: 0.8rem;
          }
        }
      }
    }

    > form {
      max-width: 21.5rem;
      background-color: var(--white);
      border-radius: 20px;
      padding: 1.5rem 1rem;
      width: 400px;

      display: flex;
      flex-direction: column;

      > label {
        font-weight: 500;
      }

      > input {
        margin-top: 0.2rem;
        border-radius: 4px;
        border: 2px solid var(--gray-400);
        padding: 0.4rem 0.3rem;
        color: var(--blue-800);

        &::placeholder {
          opacity: 0.4;
          font-style: italic;
        }

        & + label {
          margin-top: 0.7rem;
        }
      }

      > span {
        color: #eb4c4c;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 0.2rem 0 1rem;
      }

      > textarea {
        resize: none;
        margin-top: 0.2rem;
        border-radius: 4px;
        border: 2px solid var(--gray-400);
        padding: 0.4rem 0.3rem;
        color: var(--blue-800);
        height: 8rem;

        &::placeholder {
          opacity: 0.4;
          font-style: italic;
        }
      }

      > button {
        margin-top: 2rem;
        padding: 0.7rem 3.5rem;
        align-self: flex-end;
        font-size: 1rem;
        font-weight: bold;
        color: var(--white);
        background: var(--blue-500);
        border: 0;
        border-radius: 4px;
        box-shadow: 0px 6px 6px rgba(29, 36, 44, 0.25);

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }

        &:disabled {
          cursor: not-allowed;
          background: var(--gray-500);
          filter: none;
          width: 10.5rem;
          padding: 0.7rem 0;
        }
      }
    }
  }

  > footer {
     display: flex;
     flex-direction: column;
    
    > div {
      width: calc(100% - 2rem);
      height: 0.01rem;
      background: var(--gray-500);
      margin: 0 auto;
    }

    > section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 1rem;
      font-size: 0.8rem;
      color: var(--gray-500);

      > p {
        > a {
          > strong {
            font-weight: 900;
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    > div {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > form {
        margin-top: 2rem;
      }
    }

    > footer {
      > section {
        flex-direction: column;
        text-align: center;

        > p {
          margin-top: 1rem;
        }
      }
    }
  }
`;
