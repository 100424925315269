import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--blue-800);
  height: 100vh;

  > form {
      max-width: 21.5rem;
      background-color: var(--white);
      border-radius: 20px;
      padding: 1.5rem 1rem;
      width: 400px;

      display: flex;
      flex-direction: column;

      > img {
        height: 4rem;
        margin: 0 0 2rem 0;
      }

      > label {
        font-weight: 500;
      }

      > input {
        margin-top: 0.2rem;
        border-radius: 4px;
        border: 2px solid var(--gray-400);
        padding: 0.4rem 0.3rem;
        color: var(--blue-800);

        &::placeholder {
          opacity: 0.4;
          font-style: italic;
        }

        & + label {
          margin-top: 0.7rem;
        }
      }

      > span {
        color: #eb4c4c;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 0.2rem 0 1rem;
      }

      > textarea {
        resize: none;
        margin-top: 0.2rem;
        border-radius: 4px;
        border: 2px solid var(--gray-400);
        padding: 0.4rem 0.3rem;
        color: var(--blue-800);
        height: 8rem;

        &::placeholder {
          opacity: 0.4;
          font-style: italic;
        }
      }

      > button {
        margin-top: 2rem;
        padding: 0.7rem 3.5rem;
        align-self: center;
        font-size: 1rem;
        font-weight: bold;
        color: var(--white);
        background: var(--blue-500);
        border: 0;
        border-radius: 4px;
        box-shadow: 0px 6px 6px rgba(29, 36, 44, 0.25);

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }

        &:disabled {
          cursor: not-allowed;
          background: var(--gray-500);
          filter: none;
          width: 10.5rem;
          padding: 0.7rem 0;
        }
      }
    }
`;
