import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from './styles';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const About: React.FC = () => {
  const params = useParams<{slug?: string}>();

  useEffect(() => {
    if (params?.slug) {
      const element = document.getElementById(params.slug);

      element?.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth"
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [params]);
  return (
    <>
      <Header pageName="about" />

      <Container>
        <div id="sobre">
          <div>
            <h1>SOBRE NÓS</h1>
          </div>

          <p>
            A RIO TECNOLOGIA, ENGENHARIA E SERVIÇOS LTDA é uma empresa brasileira especializada em fornecer solução integrada, envolvendo o fornecimento de equipamentos, sistemas e serviços de engenharia em monitoramento ambiental nas áreas de hidrologia e meio ambiente.
          </p>
        </div>

        <div id="valores">
          <div>
            <h1>NOSSOS VALORES</h1>
          </div>

          <section>
            <h2>Nossa missão</h2>

            <p>
            A RIO TECNOLOGIA, tem a missão de Agregar valores de forma sustentável para os negócios de nossos parceiros e clientes, através de soluções otimizadas com melhor custo benefício.
            </p>
          </section>

          <section>
            <h2>Visão</h2>

            <p>
              Ser uma Empresa de referência com reconhecimento nacional na prestação de serviços e soluções otimizadas em sistemas de observação hidrometeorológicas e ambientais.
            </p>
          </section>

          <section>
            <h2>Ética</h2>

            <p>
              A Empresa RIO TECNOLOGIA, enfrenta diariamente preocupações com a eficiência, competitividade e lucratividade, porém estas questões não podem prescindir de um comportamento ético. RIO TECNOLOGIA, ENGENHARIA E SERVIÇOS LTDA, preza por princípios e valores éticos elevados e procura atingir cada vez mais níveis crescentes, primando por relacionamentos orientados pela transparência, respeito, honestidade, dignidade, lealdade, decoro, zelo, com seu público de interesse, sociedade e seus funcionários.
              Institui a valorização de seus funcionários, pela priorização às questões de saúde, segurança, preservação do meio-ambiente, e por sua contribuição ao desenvolvimento das regiões em que atua.
            </p>
          </section>
        </div>
      </Container>

      <Footer />
    </>
  );
}
