import styled from 'styled-components';

export const Container = styled.div`
  background: var(--blue-700);
  min-height: 100vh;

  > section {
    padding: 6rem 0 2rem;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    > div {
      background: var(--white);
      box-shadow: 1px 8px 10px rgba(42, 59, 69, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      width: 13rem;
      margin: 0.5rem;

      > h1 {
        text-align: center;
        height: 2rem;
        overflow: hidden;
      }

      > p {
        margin-top: 1rem;
        width: calc(100% - 2rem);
        height: 3.5rem;
        overflow: hidden;
        text-align: center;
      }

      > a {
        margin-top: 2rem;
        padding: 0.7rem 2rem;
        font-size: 1rem;
        font-weight: bold;
        color: var(--white);
        background: var(--blue-500);
        border: 0;
        border-radius: 4px;
        box-shadow: 0px 6px 6px rgba(29, 36, 44, 0.25);

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  @media (max-width: 900px) {
    > section {
      max-width: 100%;
      align-items: center;
      justify-content: center;

      > div {
        width: 45%;
      }
    }
  }
`;
