import styled from 'styled-components';

export const Container = styled.header`
  height: 4rem;
  border-bottom: 1px solid var(--blue-800);
  background: var(--blue-100);
  width: 100%;
  position: fixed;
  z-index: 2; 
`;

export const Content = styled.div`
  width: 100%;
  height: 4rem;
  padding: 0 1.5rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-child {
    display: none;
    border: 0;
    background: none;

    > svg {
      font-size: 1.3rem;
    }
  }

  > a {
    height: 4rem;

    display: flex;
    align-items: center;
    position: relative;

    img {
      height: 2.9rem;
    }

    &.active::after {
      content: '';
      height: 3px;
      border-radius: 3px 3px 0 0;
      width: 100%;
      position: absolute;
      bottom: 1px;
      left: 0;
      background: var(--blue-500);
    }
  }

  > nav {
    padding: 0 1rem;
    height: 5rem;

    > section {
      display: inline-block;
      position: relative;
      padding: 0 0.5rem;
      height: 4.5rem;
      line-height: 5rem;
      color: var(--gray-800);
      font-weight: 500;

      transition: color 0.2s;

      > button {
        border: 0;
        background: none;
        font-weight: 500;
        color: var(--gray-800);

        &:hover {
          font-weight: 900;
        }
      }

      > div {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        border-radius: 3px;
        padding: 1rem;
        background: var(--blue-100);
        box-shadow: 1px 8px 10px rgba(42, 59, 69, 0.1);
        top: 4.5rem;

        > a {
          display: flex;
          word-wrap: nowrap;
          height: 2rem;
          font-size: 0.8rem;
          font-weight: 500;
          width: 13rem;
          line-height: 2rem;

          &:hover {
           font-weight: 900;
          }
        }
      }

      & + section {
        margin-left: 1rem;
      }

      &:hover {
        font-weight: 900;
      }

      .active {
        color: var(--gray-900);
        font-weight: 900;
      }

      .active::after {
        content: '';
        height: 3px;
        border-radius: 3px 3px 0 0;
        width: 100%;
        position: absolute;
        bottom: 1px;
        left: 0;
        background: var(--blue-500);
      }
    }
  }

  > button:last-child {
    border-radius: 3rem;
    background: transparent;
    border: 2px solid var(--blue-800);
    padding: 0.6rem 0.7rem;
    
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--blue-800);
    font-weight: 600;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 0.6rem;
      color: var(--blue-800)
    }

    transition: all 0.2s;

    &:hover {
      color: var(--white);
      background: var(--blue-700);
      border: 2px solid var(--blue-700);

      svg {
        color: var(--white);
      }
    }
  }

  @media(max-width: 900px) {
    > button:first-child {
      display: flex;
    }

    > nav {
      display: none;
    }

    > button:last-child {
      display: flex;

      > svg {
        margin: 0;
      } 

      > span {
        display: none;
      }
    }
  }
`;

export const NavMobile = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--blue-800);
  color: var(--white);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button:first-child {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5rem 0 0 0.5rem;
    border: 0;
    background: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.5rem;
  }

  > button {
    margin: 1rem 0;
    font-weight: 500;
    border: 0;
    background: none;
    color: var(--white);
  }
`;
