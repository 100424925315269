import React from 'react';

import { Container } from './styles';

import { Header } from '../../components/Header';

export const Inea: React.FC = () => {
  return (
    <>
      <Header pageName="restrict"/>

      <Container>
        <section>
          <div>
            <h1>Teste</h1>

            <p>Teste</p>

            <a href="#teste">ACESSAR</a>
          </div>
        </section>
      </Container>
    </>
  );
}
