import styled from 'styled-components';

export const Container = styled.div`
  padding: 6rem 0 2rem;
  max-width: 900px;
  margin: 0 auto;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    box-shadow: 1px 8px 10px rgba(42, 59, 69, 0.1);
    background: var(--blue-100);
    border-radius: 6px;
    padding: 2rem 0;

    > div {
      background: var(--blue-800);
      margin-bottom: 1.5rem;
      padding: 0.7rem 0;
      width: 100%;

      > h1 {
        color: var(--white);
        padding: 0 2rem;
      }
    }

    > p {
      width: calc(100% - 4rem);
      padding: 0 2rem;
    }

    > section {
      > h2 {
        margin-top: 1.5rem;
        padding: 0 2rem;
      }

      > p {
        margin-top: 0.5rem;
        padding: 0 2rem;
      }
    }
  }
`;
