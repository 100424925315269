import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FaLock, FaSignOutAlt, FaBars } from 'react-icons/fa';

import { Container, Content, NavMobile } from './styles';

import Logo from '../../assets/logo.svg';

type HeaderProps = { pageName?: string };

export function Header({ pageName }: HeaderProps) {
  const [subNavisActive, setSubNavisActive] = useState('none');
  const [navMobileisActive, setNavMobileisActive] = useState(false);

  const history = useHistory();

  function scrollToElement(id: string) {
    const element = document.getElementById(id);

    element?.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth"
    });
  }

  function handleSignout() {
    localStorage.removeItem('@rio-tec');

    localStorage.removeItem('@rio-tec-inea');

    history.push('/login');
  }

  function handleClickItemNavMobile(route: string, isContact: boolean) {
    setNavMobileisActive(false);

    if (isContact) {
      scrollToElement('contato');
    } else {
      history.push(route);
    }
  }

  return(
    <Container>
      <Content>
        <button onClick={() => setNavMobileisActive(true)}>
          <FaBars />
        </button>

        <Link onClick={() => window.scrollTo(0, 0)} to="/">
            <img src={Logo} alt="Rio" />
        </Link>
      
        <nav>
          <section>
            <Link onClick={() => window.scrollTo(0, 0)} to="/" className={pageName === 'home' ? 'active' : ''}>
              INÍCIO
            </Link>
          </section>

          <section
            onMouseOver={() => setSubNavisActive('1')}
            onMouseLeave={() => setSubNavisActive('none')}
          >
            <Link to="/empresa" className={pageName === 'about' ? 'active' : ''}>
              EMPRESA
            </Link>

            {subNavisActive === '1' && (
              <div>
                <Link to="/empresa/sobre">
                  SOBRE NÓS
                </Link>
                <Link to="/empresa/valores">
                  NOSSOS VALORES 
                </Link>
              </div>
            )}
          </section>

          <section
            onMouseOver={() => setSubNavisActive('2')}
            onMouseLeave={() => setSubNavisActive('none')}
          >
            <Link to="/servicos" className={pageName === 'actuation' ? 'active' : ''}>
              SERVIÇOS
            </Link>

            {subNavisActive === '2' && (
              <div>
                <Link to="/servicos/hidrometria">
                  HIDROMETRIA
                </Link>
                <Link to="/servicos/monitoramento">
                  MONITORAMENTO TELEMÉTRICO 
                </Link>
                <Link to="/servicos/instalacao">
                  INSTALAÇÃO E OPERAÇÃO 
                </Link>
                <Link to="/servicos/manutencao">
                  MANUTENÇÃO
                </Link>

                <Link to="/servicos/locacao">
                  LOCAÇÃO
                </Link>

                <Link to="/servicos/desenvolvimento">
                  FORNECIMENTO DE SOFTWARE 
                </Link>
              </div>
            )}
          </section>

          <section
            onMouseOver={() => setSubNavisActive('3')}
            onMouseLeave={() => setSubNavisActive('none')}
            style={{ display: 'none' }}
          >
            <Link to="/equipamentos" className={pageName === 'equip' ? 'active' : ''}>
              EQUIPAMENTOS
            </Link>

            {subNavisActive === '3' && (
              <div>
                <Link to="/equipamentos">
                  ESTAÇÕES METEOROLOGICAS
                </Link>
                <Link to="/equipamentos">
                  ESTAÇÕES HIDROLÓGICAS 
                </Link>
                <Link to="/equipamentos">
                  ESTAÇÕES QUALIDADE DA ÁGUA 
                </Link>
              </div>
            )}
          </section>

          <section
            onMouseOver={() => setSubNavisActive('4')}
            onMouseLeave={() => setSubNavisActive('none')}
            style={{ display: 'none' }}
          >
            <Link to="/suporte" className={pageName === 'suport' ? 'active' : ''}>
              SUPORTE
            </Link>

            {subNavisActive === '4' && (
              <div>
                <Link to="/suporte">
                  TREINAMENTO
                </Link>
                <Link to="/suporte">
                  DOWNLOADS 
                </Link>
              </div>
            )}
          </section>

          {pageName !== 'login' && pageName !== 'restrict' && (
            <section>
              <button onClick={() => scrollToElement('contato')}>
                CONTATO
              </button>
            </section>
          )}
        </nav>

        { pageName === 'login' ? (
          <button 
            disabled 
            style={{ 
              background: 'var(--blue-800)', 
              color: 'var(--white)',
              cursor: 'default'
            }} 
          >
            <FaLock color="#FFF" />
            <span>Acesso restrito</span>
          </button>
        ) : pageName === 'restrict' ? (
          <button style={{ padding: '0.6rem 1.5rem' }} onClick={handleSignout}>
            <FaSignOutAlt />
            <span>Sair</span>
          </button>
        ) : (
          <button onClick={() => history.push('/login')}>
            <FaLock />
            <span>Acesso restrito</span>
          </button>
        )}
      </Content>

      {navMobileisActive && (
        <NavMobile>
          <button onClick={() => setNavMobileisActive(false)}>x</button>

          <button onClick={() => handleClickItemNavMobile('/', false)}>INÍCIO</button>
          <button onClick={() => handleClickItemNavMobile('/empresa', false)}>EMPRESA</button>
          <button onClick={() => handleClickItemNavMobile('/servicos', false)}>SERVIÇOS</button>
          {pageName !== 'login' && pageName !== 'restrict' && (
            <button onClick={() => handleClickItemNavMobile('/', true)}>CONTATO</button>
          )}
        </NavMobile>
      )}
    </Container>
  )
}