import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { FaPhoneAlt, FaWhatsapp, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

import { ContactContainer } from './styles';

// import { phoneMask } from '../../utils/phoneMask';

const schema = Yup.object().shape({
  name: Yup.string().required('POR FAVOR, DIGITE SEU NOME'),
  email: Yup.string()
    .email('POR FAVOR, DIGITE UM E-MAIL VÁLIDO')
    .required('POR FAVOR, DIGITE SEU E-MAIL'),
  phone: Yup.string()
    .min(8, 'DIGITE UM NÚMERO VÁLIDO')
    .max(15, 'DIGITE UM NÚMERO VÁLIDO')
    .required('POR FAVOR, DIGITE SEU TELEFONE'),
  message: Yup.string().required('POR FAVOR, DIGITE SUA MENSAGEM'),
});

export function Footer() {
  const { register, reset, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [loading, setLoading] = useState(false);

  // function onSubmit(formInfo: any) {
  //   console.log(formInfo);
  //   setLoading(true);

  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000)
  // }


  async function sendMail(formInfo: any) {
    setLoading(true);

    const data = {
      service_id: 'default_service',
      template_id: 'contact',
      user_id: 'user_XHetkUumdsZSGKULEhTOV',
      template_params: {
        assunto: 'CONTATO PELO SITE DA RIO',
        nome: formInfo.name,
        email: formInfo.email,
        telefone: formInfo.phone,
        mensagem: formInfo.message,
      },
    };

    await emailjs
      .send(
        data.service_id,
        data.template_id,
        data.template_params,
        data.user_id
      )
      .then(
        result => {
          console.log(result);
          toast.success(
            'Menssagem enviada, em breve entraremos em contato.',
            {
              className: 'toast',
            }
          );
        },
        error => {
          console.log(error);
          toast.error(
            'Falha no envio, por favor verifique seus dados e tente novamente.',
            {
              className: 'toast',
            }
          );
        }
      );

    setLoading(false);
    reset();
  }

  return(
    <ContactContainer>
      <div id="contato">
        <section>
          <h1>Fale conosco</h1>
          <h2>Nosso horário de funcionamento é de 8:00h às 18:00h, de segunda à sexta.</h2>

          <div>
            <section>
              <FaPhoneAlt />
              <a href="tel:02125893851">(21) 2589-3851</a>
            </section>

            <section>
              <FaWhatsapp />
              <a href="https://api.whatsapp.com/send?phone=5521972082394" target="_blank" rel="noreferrer">
                (21) 97208-2394
              </a>
            </section>

            <section>
              <FaEnvelope />
              <a href="mailto:contato@riotecnologiaambiental.com.br?subject=CONTATO PELO SITE">
                contato@riotecnologiaambiental.com.br
              </a>
            </section>

            <section>
              <FaEnvelope />
              <a href="mailto:silvio@riotecnologiaambiental.com.br?subject=CONTATO PELO SITE">
                silvio@riotecnologiaambiental.com.br
              </a>
            </section>

            <section>
              <FaMapMarkerAlt />
              <a 
                href="https://www.google.com/maps/place/Condom%C3%ADnio+do+Ed+Comercial+Santa+Isabel+-+R.+Leopoldina+Rego,+198+-+Ramos,+Rio+de+Janeiro+-+RJ,+21021-520/@-22.8525495,-43.2635653,17z/data=!3m1!4b1!4m5!3m4!1s0x997bfa70899b55:0x4ee6ed38376249ee!8m2!3d-22.8525545!4d-43.2613766" target="_blank"
                rel="noreferrer">
                Rua Leopoldina Rego, 198 - Sala 201 - Ramos - RJ
              </a>
            </section>
          </div>
        </section>

        <form onSubmit={handleSubmit(sendMail)}>
          <label>Nome*</label>
          <input 
            maxLength={100} 
            type="text" 
            placeholder="Digite seu nome..."
            {...register('name')}
          />
          {errors.name && <span>{errors.name.message}</span>}

          <label>Celular*</label>
          <input 
            placeholder="Digite seu Telefone..."
            maxLength={15}
            {...register('phone')}
          />
          {errors.phone && <span>{errors.phone.message}</span>}

          <label>E-mail*</label>
          <input 
            placeholder="Digite seu e-mail..."
            maxLength={100}
            {...register('email')}
          />
          {errors.email && <span>{errors.email.message}</span>}

          <label>Mensagem*</label>
          <textarea
            placeholder="Digite sua mensagem..."
            maxLength={500}
            {...register('message')}
          />
          {errors.message && <span>{errors.message.message}</span>}

          {loading ? (
            <button type="button" disabled>ENVIANDO...</button>
          ) : (
            <button type="submit">ENVIAR</button>
          )}
        </form>
      </div>

      <footer>
        <div />

        <section>
          <p>© 2021 - <strong>RIO TECNOLOGIA, ENGENHARIA E SERVIÇOS LTDA</strong> - Todos os direitos reservados</p>
          <p>Desenvolvido por <a href="https://www.linkedin.com/in/mouralipe/"><strong>@mouralipe</strong></a></p>
        </section>
      </footer>
    </ContactContainer>
  );
}