import React from 'react';
import { Router, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'swiper/swiper-bundle.css';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from './styles/global';

import { Routes } from './routes';
import { history } from './services/history';


export default function App() {
  return (
    <BrowserRouter>
      <Router history={history}>
        <Routes />
        <GlobalStyle />
        <ToastContainer autoClose={3000} />
      </Router>
    </BrowserRouter>
  );
}
