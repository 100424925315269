import React from 'react';

import { Container } from './styles';

import { Header } from '../../components/Header';

export const Restricted: React.FC = () => {
  return (
    <>
      <Header pageName="restrict"/>

      <Container>
        <section>
          <div>
            <h1>EDF</h1>

            <p>EDF Norte Fluminense</p>

            <a href="http://ute.inforio.net/portal/#p=ultimos" target="_blank" rel="noreferrer">ACESSAR</a>
          </div>

          <div>
            <h1>MetInfo</h1>

            <p>Sistema de monitoramento MetInfo</p>

            <a href="http://lagoa.ambiental.rio.br/MetInfo/" target="_blank" rel="noreferrer">ACESSAR</a>
          </div>
        </section>
      </Container>
    </>
  );
}
