import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface RouteWrapperProps extends RouteProps {
  component: React.FC;
  isPrivate?: boolean;
  isInea?: boolean;
  isLogin?: boolean;
}

export function RouteWrapper({
  component: Component,
  isPrivate = false,
  isInea = false,
  isLogin = false,
  ...rest
} : RouteWrapperProps) {

  const signed = localStorage.getItem('@rio-tec');

  const signedStorage = signed && JSON.parse(signed);

  const inea = localStorage.getItem('@rio-tec-inea');

  const ineaStorage = inea && JSON.parse(inea);

  if (!signedStorage && !ineaStorage && (isPrivate || isInea)) {
    return <Redirect to="/login" />;
  }

  if ((signedStorage && isLogin) || (signedStorage && isInea)) {
    return <Redirect to="/restrito" />;
  }

  if ((ineaStorage && isLogin) || (ineaStorage && isPrivate)) {
    return <Redirect to="/inea" />;
  }
  // eslint-disable jsx-props-no-spreading
  return <Route {...rest} component={Component} />;
}

