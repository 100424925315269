import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWrapper } from './Route';

import { Home } from '../pages/Home';
import { About } from '../pages/About';
import { Actuation } from '../pages/Actuation';
import { Equips } from '../pages/Equips';
import { Suport } from '../pages/Suport';

import { Login } from '../pages/Login';

import { Restricted } from '../pages/Restricted';
import { Inea } from '../pages/Inea';


export function Routes() {
  return (
    <Switch>
      <RouteWrapper path="/" exact component={Home} />

      <RouteWrapper path="/empresa" exact component={About} />
      <RouteWrapper path="/empresa/:slug" component={About} />

      <RouteWrapper path="/servicos" exact component={Actuation} />
      <RouteWrapper path="/servicos/:slug" component={Actuation} />
      
      <RouteWrapper path="/equipamentos" component={Equips} />
      <RouteWrapper path="/suporte" component={Suport} />

      <RouteWrapper path="/login" isLogin component={Login} />

      <RouteWrapper path="/restrito" component={Restricted} isPrivate />
      <RouteWrapper path="/inea" component={Inea} isInea />
    </Switch>
  )
}