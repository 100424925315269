import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { FaAngleRight } from 'react-icons/fa';

import { Container } from './styles';

import { Header } from '../../components/Header';

import { Footer } from '../../components/Footer';

export function Actuation() {

  const params = useParams<{slug?: string}>();
  const history = useHistory();

  useEffect(() => {
    if (params?.slug) {
      const element = document.getElementById(params.slug);

      element?.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth"
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [params]);

  return (
    <>
      <Header pageName="actuation"/>

      <Container>
        <div id="hidrometria">
          <section>
            <h1>HIDROMETRIA</h1>
          </section>

          <p>Realização de serviços de implantação de sistemas de monitoramento hidrometeorológico e de estudos hidrológicos básicos, para o conhecimento das características hidrológicas de uma determinada região visando o aproveitamento racional de seus recursos hídricos naturais. Abrangendo:</p>

          <div>
            <FaAngleRight />
            <p>Instalação, operação e monitoramento de estações fluviométricas, pluviométricas e meteorológicas convencionais e/ou telemétricas providas de sensores automáticos.</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Monitoramento dos níveis de água e precipitação, remotamente em tempo real, visando a implantação de sistema de alarme de eventos extremos;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Estabelecimento de seção transversal de medição de vazões líquidas em cursos d’água e determinação da vazão em trânsito na seção, a partir da realização de medições e integração da distribuição da velocidade do fluxo por método convencional com molinete hidrométrico e método acústico com ADCP;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Definição da curva de correlação cota-vazão (curva chave), análise de consistência das medições e dos efeitos da mudança do controle e do regime de escoamento, extrapolação e atualização da curva chave;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Tratamento de dados hidrológicos observados, através de software próprio, realizando a análise de consistência dos dados, identificando e eliminando os erros grosseiros, sistemáticos e fortuitos, preenchendo falhas e identificando valores médios e extremos da série temporal;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Estruturação e alimentação de Banco de Dados Hidrometeorológicos.</p>
          </div>
        </div>

        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="sedimentometria">
          <section>
            <h1>SEDIMENTOMETRIA</h1>
          </section>

          <p>Realização de serviços de coleta de amostra de sedimentos em suspensão e de fundo em seções transversais de medição de vazão liquida e determinação da das descargas sólidas em suspensão, de fundo e total, caracterizando o transporte de sedimentos em cursos d’água, como descrito a seguir:</p>

          <div>
            <FaAngleRight />
            <p>Amostragem de sólido em Suspensão – utilizando amostradores padrão, escolhidos de acordo com o porte do rio e a velocidade do escoamento, serão feitas coletas de material em suspensão, por integração vertical, em algumas verticais, de modo a representar a distribuição dos sedimentos em suspensão ao longo da seção de medição de vazão.</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Amostragem de material de fundo –utilizando amostrador do tipo penetração vertical de escavação (US-BM-54), para rios de leito arenoso, serão feitas coletas de amostra de material do fundo em no mínimo 3 verticais (a 1/4, a 1/2 e a 3/4 da largura da seção transversal).</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Determinação em laboratório da concentração de material em suspensão e da granulometria do material de fundo.</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Determinação da descarga sólida em suspensão, de fundo e total, utilizando fórmulas de cálculo consagradas;</p>
          </div>
        </div>

        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="topobatimetria">
          <section>
            <h1>TOPOBATIMETRIA</h1>
          </section>

          <p>Realização de levantamentos topobatimétricos de seções transversais em rios, canais, lagos e reservatórios para controle dos processos de erosão e assoreamento, abrangendo::</p>

          <div>
            <FaAngleRight />
            <p>Levantamento topográfico da parte seca da seção transversal a partir do seu nivelamento geométrico;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Batimetria da parte molhada da seção utilizando guincho hidrométrico ou ecobatimetro e para o posicionamento do barco: teodolitos, distanciômetro ou gps;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>A topobatimetria de lagos e reservatórios será precedido de um planejamento anterior identificando as diversas seções transversais que deverão ser levantadas.</p>
          </div>
        </div>

        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="qualidade">
          <section>
            <h1>QUALIDADE DA ÁGUA</h1>
          </section>

          <p>Realização de programas de monitoramento da qualidade da água em rios, canais, lagos e reservatórios visando avaliar o equilíbrio ecológico destes ambientes aquáticos e seu estado de conservação. O monitoramento visa identificar as substâncias presentes nesses corpos hídricos, medidas por meio de parâmetros que traduzem as características físicas, químicas e biológicas das amostras de água coletadas. Esse monitoramento abrange as seguintes tarefas:</p>

          <div>
            <FaAngleRight />
            <p>Coleta de Amostras de Água –Precedida de um planejamento sobre a localização dos pontos de monitoramento, da frequência e período das coletas, dos parâmetros a serem monitorados e consequentemente do tipo de amostra a ser coletada, as equipes de campo da Rio Tecnologia Ambiental executarão a coleta das amostras de água respeitando as recomendações da ABNT/NBR 9898 de1987;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Transporte das Amostras – Os frascos com as amostras coletadas serão acondicionados adequadamente e transportadas para o laboratório respeitando-se os prazos para análise preconizados na ABNT/NBR 9898 de1987 e preservando a sua integridade;</p>
          </div>

          <div>
            <FaAngleRight />
            <p>Analises em Laboratório – As amostras coletadas serão sujeitas a técnicas de análises laboratorial com aplicação na avaliação quantitativa de parâmetros indicadores da qualidade da água característica da condição ambiental do corpo hídrico em estudo.</p>
          </div>
        </div>
        
        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>
        
        <div id="monitoramento">
          <section>
            <h1>MONITORAMENTO TELEMÉTRICO</h1>
          </section>

          <p>Adequação, implantação de Estações telemétricas para monitoramento automático de parâmetros ambientais, processamento dos dados para atendimento a resolução conjunta nº 03 ANA/ANEEL de agosto 2010; utilizando tecnologias via rádio, GPRS, Satélite, fibra ótica de acordo com a necessidade do cliente.</p>
        </div>

        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="instalacao">
          <section>
            <h1>INSTALAÇÃO E OPERAÇÃO</h1>
          </section>

          <p>Implantação de sistema que viabiliza o monitoramento e operação das estações telemétricas e dispõe de capacidade de processamento, armazenamento adequados a cada necessidade, gera tabelas, gráficos e/ou download em formatos de arquivos *.txt e *csv para elaboração de relatórios e oferece interação remota com as estações telemétricas por meio de acionamento de eventos de forma individual ou simultânea.</p>
        </div>

        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="manutencao">
          <section>
            <h1>MANUTENÇÃO PREVENTIVA E CORRETIVA</h1>
          </section>

          <p>
            Dispõe de uma equipe técnica altamente qualificada em: avaliar as condições de infraestrutura, equipamentos, rede de estações ambientais complexas e propor correções, adequações e/ou melhorias necessárias; estabelecer planos de manutenções preventivas e corretivas envolvendo ajustes, calibrações necessárias para restabelecer o adequado funcionamento do sistema.
            Capaz de assumir contratos de manutenções preventivas, corretivas de rede de estações hidrológicas, climatológicas, alerta e alarme sonoros, qualidade da água e ar com ou sem fornecimentos de peças e insumos de acordo com a demanda exigida pelo cliente.
          </p>
        </div>
        
        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="locacao">
          <section>
            <h1>LOCAÇÃO</h1>
          </section>

          <p>
            Locação por prazo certo ou indeterminado envolvendo fornecimento, instalação e manutenção com fornecimento de equipamentos e insumos para estações telemétricas com sensores automáticos: hidrológicas, climatológicas, sirenes para alerta e alarme, implantação de software/ sistema personalizado para visualização e manipulação dos dados em tempo real e capaz de atender simultaneamente o processamento e transmissão de dados para atendimento a resolução ANA/ANEEL nº 03 de agosto 2010.
          </p>
        </div>

        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>

        <div id="desenvolvimento">
          <section>
            <h1>DESENVOLVIMENTO DE SOFTWARE</h1>
          </section>

          <p>
            Soluções personalizadas em software de monitoramento remoto de dados.   Permite acesso aos dados de estações telemétricas remotamente em tempo real por dispositivos móveis de forma segura e acionável, geração de gráficos, download dos dados em formato *.txt, *.CSV, processamento e transmissão de dados para atender a resolução conjunta ANA/ANEEL nº 03 de agosto 2010.
          </p>
        </div>
        
        <section>
          <button onClick={() => history.push('/')} type="button">Voltar para o início</button>
        </section>
      </Container>

      <Footer />
    </>
  );
}
