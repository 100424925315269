import styled from 'styled-components';

import backgroundHome from '../../assets/backgroundHome.png';

export const Container = styled.div`
  width: 100%;
  padding-top: 4rem;
`;

export const HomeContainer = styled.div`
  height: calc(100vh - 4rem);
  background: url(${backgroundHome}) no-repeat;
  background-attachment: fixed;
  background-size: cover;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);

    > h1 {
      color: var(--white);
      font-size: 3rem;
      font-weight: 900;
    }

    .slideContainerBanner {
      width: 26rem;
      height: 11rem;
      align-items: center;
      justify-content: center;
    }

    .slideBanner {
      > p {
        color: var(--gray-200);
        font-size: 1.9rem;
        font-weight: 600;
        width: 26rem;
        margin-top: 2rem;
        padding: 0 0.5rem;
      }
    }

    

    > button {
      margin-top: 3rem;
      padding: 0.8rem 2.5rem;
      font-size: 1.3rem;
      font-weight: bold;
      color: var(--white);
      background: var(--blue-500);
      border: 0;
      border-radius: 4px;
      box-shadow: 0px 6px 6px rgba(29, 36, 44, 0.25);

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;


export const ServicesContainer = styled.div`
  width: 100%;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--blue-100);

  > h1 {
    font-size: 2rem;
  }

  > section {
    margin: 2rem auto 0;
    max-width: 900px; 

    .slide {
      width: 18rem;
      background: var(--white);
      box-shadow: 1px 8px 10px rgba(42, 59, 69, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: justify;
      padding-bottom: 1.5rem;
      margin-bottom: 3rem;
      

      > img {
        width: 100%;
        border-radius: 10px 10px 0 0;
      }

      > h2 {
        margin-top: 1rem;
        font-size: 1.22rem;
        color: var(--blue-800);
      }

      > p {
        margin-top: 1rem;
        font-size: 0.9rem;
        color: var(--blue-900);
        height: 7.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 16rem;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
      }

      > button {
        margin-top: 2rem;
        padding: 0.7rem 2rem;
        font-size: 1rem;
        font-weight: bold;
        color: var(--white);
        background: var(--blue-500);
        border: 0;
        border-radius: 4px;
        box-shadow: 0px 6px 6px rgba(29, 36, 44, 0.25);

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  @media(max-width: 900px) {
    display: none;
  }
`;

export const PartnersContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--gray-100);
  padding: 3rem 0 4.5rem;

  > h1 {
    font-size: 2rem;
  }

  > div { 
    margin: 2rem auto 0;
    max-width: 900px;

    .slide {
      > img {
        height: 9rem;
      }
    }
  }

  @media(max-width: 900px) {
    display: none;
  }
`;
