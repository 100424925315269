import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    --white: #fff;

    --gray-50: #EEE;
    --gray-100: #FDFDFD;
    --gray-200: #e5e5e5;
    --gray-300: #CFCACA;
    --gray-400: #C4C4C4;
    --gray-500: #C6C6C6;
    --gray-600: #B6B6B6;
    --gray-900: #121214;
    
    --blue-100: #F6F9FF;
    --blue-500: #4E84D9;
    --blue-700: #475873;
    --blue-800: #3C4866;
    --blue-900: #27292E;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--gray-100);
    color: var(--blue-800);
    
  }

  body, input, textarea, select, button {
    font: 400 1rem "Roboto", sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .swiper-button-prev, .swiper-button-next {
    color: var(--gray-200);
    opacity: 0.4;
    font-weight: 900;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.2);
    }
  }

  .toast {
    font-size: 1rem;
    font-weight: bold;
  }
`;